// Import Fuse core library
@use '@angular/material' as mat;
@import '@fuse/scss/core';

// Import app.theme.scss
@import 'app/app.theme';

/** SET REQUIRED AS BIG! **/
.mat-placeholder-required.mat-form-field-required-marker {
  vertical-align: text-top;
  font-size: 1.1em;
  padding-right: 1px;
}

// Lists normally have to fixed 24 padding with p-24. However, that it not suitable for mobile widths
.list-p-24 {
  padding: 24px;
  @include media-breakpoint('xs') {
    padding: 8px !important;
  }
}

mat-table {
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  margin-right: 2px !important;
  margin-left: 2px !important;
}
.mat-row {
  border-top: 0;
}
.mat-row.ng-trigger:hover {
  background: lightyellow !important;
  cursor: pointer;
}

.table-element-detail {
  background: white !important;
  cursor: default;
}

// Styles to non legacy dialog
.mat-mdc-dialog-title {
  font:
    500 20px / 32px Lato,
    Helvetica Neue,
    Arial,
    sans-serif !important;
  letter-spacing: normal !important;
  padding-left: 0 !important;
  padding-bottom: 16px !important;
  line-height: 1.4 !important;
}
mat-dialog-actions {
  padding: 40px !important;
  @include media-breakpoint('lt-sm') {
    padding: 25px 18px 40px 18px !important;
  }
}
.mat-mdc-dialog-content {
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif !important;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: unset !important;
  padding: 24px 24px 24px 24px !important;
  line-height: 1.4 !important;
  @include media-breakpoint('lt-sm') {
    padding: 25px 18px 40px 18px !important;
  }

  h3 {
    font-weight: bolder;
  }
  textarea {
    padding-right: 0 !important;
  }
}

mat-mdc-dialog-content {
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif !important;
  letter-spacing: unset !important;
  padding: 40px !important;
  font-size: 16px !important;

  h3 {
    font-weight: bolder;
  }
  textarea {
    padding-right: 0 !important;
  }
}

.mat-mdc-dialog-actions {
  padding: 24px 24px 24px 24px !important;
  justify-content: end !important;
  @include media-breakpoint(lt-sm) {
    padding: 24px 18px 24px 18px !important;
  }
}

//-- Mat Tooltip Styles
.mat-tooltip {
  max-width: 400px !important;
  overflow: hidden;
  overflow-wrap: anywhere !important;
  display: -webkit-box;
  -webkit-line-clamp: 50; // This sets maximum number of lines before ellipsis
  -webkit-box-orient: vertical;
}

//-- Checkbox styles
mat-checkbox .mdc-form-field .mdc-label {
  margin-bottom: -22px !important;
  margin-left: 8px !important;
}

.mat-mdc-checkbox {
  //margin-bottom: -18px;
  height: 28px;
}

mat-checkbox span {
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif;
  word-break: break-word;
}

// Touch target is the area that registers click as if it is the checkbox itself.
.mat-mdc-checkbox-touch-target {
  top: 12px !important;
  height: 28px !important;
  width: 28px !important;
}
// Move the actual input to match the visual representation made by material
.mdc-checkbox__native-control {
  height: 28px !important;
}

.mdc-checkbox__background {
  top: 3px !important;
}

// Label is the span of text next to the checkbox
.mdc-form-field > label {
  margin-top: -16px;
  margin-left: -8px !important;
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif;
}
// Sets selected checkbox background color to yellow
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  background-color: #f3e41a !important;
  border-color: #f3e41a !important;
}
// Sets disabled checkbox background color to grey
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:disabled:checked
  ~ .mdc-checkbox__background {
  background-color: #b6b6b6 !important;
}

// Focus and click shadows seem to have vanished
// Even if all the custom styles are removed the ripple doesn't show
.mat-mdc-checkbox-ripple {
  height: 36px;
  width: 36px;
  top: -6px !important;
  left: 2px !important;
}

// For some reason, by default, the pointer-events for mat-label are disabled.
// Enable pointer events so the tooltips will work
// Disable pointer events if input is disabled (tooltip is not shown for disabled input)
label.mat-form-field-label {
  pointer-events: auto;

  .mat-form-field-disabled & {
    pointer-events: none;
  }
}

// Add styles for input when value is changed
// This is related to value-changed.directive.ts
.mat-form-field-value-changed {
  .mat-form-field-outline {
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-color: #f3e41a;
      border-width: 2px;
    }
  }
}

body.theme-default {
  .mat-form-field-appearance-outline {
    &.mat-form-field-warning:not(.mat-form-field-disabled) {
      .mat-form-field-outline-thick {
        color: #dd8d00;
        border-width: 2px;
        opacity: 1;
      }

      mat-hint {
        color: #dd8d00;
      }
    }
  }
}

.mat-form-field-no-outline {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-outline {
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          border-width: 0px;
        }
      }
    }
  }
}

// In Safari/ Webkit browsers the label gap was in wrong place, this hack will fix it.
.mat-form-field-appearance-outline .mat-form-field-outline-start {
  width: 7px !important;
}

mat-icon[color='green'] {
  color: green;
}
mat-icon[color='grey'] {
  color: grey;
}
mat-icon[color='red'] {
  color: red;
}
mat-icon[color='customPurple'] {
  color: rgb(126, 36, 185);
}
div.mat-menu-panel {
  &.extensionsMenu {
    background: none;
    box-shadow: none;
    button {
      margin-bottom: 10px;
    }
  }
}

.y-overflow-scroll {
  overflow-y: scroll;
}

#container-3 {
  // padding-top: 58px;
}

.toolbar-padding {
  padding-top: 58px;
  transition-delay: 1200ms;
  transition-property: padding-top;
}

// Snackbar Style
.success-snack-bar,
error-snack-bar,
info-snack-bar {
  color: white !important;
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 14px;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: white !important;
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif !important;
  font-size: 14px !important;
}

.mat-mdc-snack-bar-action {
  color: #f3e41a !important;
  font-family:
    Lato,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.mat-mdc-snack-bar-container {
  background: #2a2a2a;
  border-radius: 4px;
}

.mat-mdc-snack-bar-container {
  margin: 24px !important;
}

// List color styles
.pendingActionRow {
  background-color: #ffffff !important;
}
.completedRow {
  background-color: #f2f2f2 !important;
}
.closedRow {
  background-color: #e5e5e5 !important;
}

.yellowRow {
  background-color: #f3e41a20 !important;
}
.redRow {
  background-color: #bb031220 !important;
}
.grayRow {
  background-color: #bbbbbb !important;
}

// Dialog header (mat-toolbar)
mat-toolbar {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.mat-toolbar-row {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

// List badge style (as seen in quotation statuses)
.statusBadge {
  padding: 3px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  background: #f0f0f0;
  text-weigh: 100;
}

.statusText {
  width: 100%;
  text-align: center;
  display: block;
}
.statusText div {
  text-align: center;
  font-size: 0.8em;
  word-break: break-word;
}

// -----------------------------------------------------------------------------------------------------
// @ Authentication Page Styles
// -----------------------------------------------------------------------------------------------------
// -verify email: with/with out pw.
// -Quick Register: Already exists / new
// -login
// -Reset password
// -forgot password
// Function for returning the tint for a specific color.
// The percentage value has to be the difference between 100 and the the actual tint value
@function tint($color, $percentage) {
  @return mix(#fff, $color, $percentage);
}

$mp-blue: #142438;
$mp-red: #d81159;
$mp-yellow: #f3e41a;
$myplane-grey: #1d1d1b;
$myplane-grey-80: tint($myplane-grey, 20%);
$myplane-grey-70: tint($myplane-grey, 30%);

.auth-page {
  width: 100%;
  overflow: hidden;

  .auth-page-intro {
    background-color: #f3e41a;
    @include media-breakpoint('sm') {
      padding: 128px 64px;
    }

    .title {
      font-size: 10vh;
      font-weight: 300;
      line-height: 1;
      margin-top: 30vh;
      position: relative;
      display: block;
      right: 210px;
      color: $mp-blue;
      @include media-breakpoint(lt-lg) {
        right: 10rem;
      }
    }

    .description {
      padding-top: 16px;
      font-size: 20px;
      max-width: 600px;
    }
  }

  .auth-page-terms {
    font-family: 'Lato', serif;
    font-weight: 300 !important;
    margin-top: 1em;
    color: #555;
  }

  .auth-page-form-wrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background: url('./assets/images/backgrounds/bg-login.jpeg') no-repeat !important;
    background-size: cover !important;
    padding-left: 80px;
    padding-bottom: 30px;
    @include mat.elevation(16);

    @include media-breakpoint('sm') {
      width: 360px;
      min-width: 360px;
      max-width: 360px;
      padding-left: 0px;
    }

    @include media-breakpoint('xs') {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      padding-left: 0;
    }

    .auth-page-form {
      padding: 128px 48px 48px 48px;
      width: 380px;
      min-width: 380px;
      max-width: 380px;
      @include media-breakpoint('xs') {
        text-align: center;
        margin: 0 auto !important;
        width: unset;
        min-width: unset;
        padding: 20px 20px 0 20px;

        .logo {
          margin-bottom: 10px !important;
        }
      }
      @include media-breakpoint('sm') {
        padding: 100px 48px 0 48px;
        margin: 0 auto !important;
        width: unset;
        min-width: unset;
      }

      .description {
        padding-top: 8px;
        font-family: 'Lato', serif;
      }

      form {
        width: 100%;
        padding-top: 32px;
        padding-left: 0 !important;

        mat-form-field {
          width: 100%;
          @include media-breakpoint('xs') {
            width: 100%;
          }

          &.mat-form-field-appearance-fill .mat-form-field-flex {
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 30px;
          }

          .mat-form-field-underline {
            display: none;
          }
        }

        .submit-button {
          width: 100%;
          margin: 16px auto;
          display: block;
          height: 50px;
        }

        .active {
          color: white;
          background: linear-gradient(
            -38deg,
            $myplane-grey-80 0%,
            $myplane-grey-80 33%,
            $myplane-grey-70 33%,
            $myplane-grey-70 100%
          );

          &:hover {
            color: $myplane-grey;
            background: $mp-yellow;
          }
        }
      }

      .separator {
        font-size: 15px;
        font-weight: 600;
        margin: 24px auto;
        position: relative;
        overflow: hidden;
        width: 100px;
        text-align: center;

        .text {
          display: inline-flex;
          position: relative;
          padding: 0 8px;
          z-index: 9999;

          &:before,
          &:after {
            content: '';
            display: block;
            width: 30px;
            position: absolute;
            top: 10px;
            border-top: 1px solid;
          }

          &:before {
            right: 100%;
          }

          &:after {
            left: 100%;
          }
        }
      }

      a {
        color: $mp-blue;

        :hover {
          color: $mp-yellow;
        }
      }

      .logo {
        margin-bottom: 40px;
        max-width: 360px;
      }
    }
  }
}

// GOOGLE MAPS
.google-map-marker-label,
.google-map-cluster-marker-label {
  padding: 3px;
  border-radius: 4px;
  white-space: normal;
  max-width: 80px;
  background-color: $mp-yellow;
  color: $mp-blue !important;
}

google-map .map-container {
  height: 100% !important;
  min-height: 400px;
}

.card {
  border-top: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15) !important;
}

// BLUR EFFECT (please use blur-overlay component)
.blurred {
  display: block;
  filter: blur(8px);
  touch-action: none !important;
  -ms-touch-action: none !important;
  -webkit-touch-callout: none !important;
  pointer-events: none !important;
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Old versions of Firefox */
  -ms-user-select: none !important; /* Internet Explorer/Edge */
  user-select: none !important; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  * {
    touch-action: none !important;
    -ms-touch-action: none !important;
    -webkit-touch-callout: none !important;
    pointer-events: none !important;
    -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important; /* Safari */
    -khtml-user-select: none !important; /* Konqueror HTML */
    -moz-user-select: none !important; /* Old versions of Firefox */
    -ms-user-select: none !important; /* Internet Explorer/Edge */
    user-select: none !important; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    ::selection {
      // For some reason, some mat-label elements are still highlightable, this hides the highlight
      background: none !important;
    }
  }
}

/* Previous value component History tooltip spacing **/
.history-tooltip {
  white-space: pre-line;
}
